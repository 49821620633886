import { ArticleLogsTypes } from "./types";
import { articleLogs } from "../../../api/index";

export const submitArticleLogs = (lesson) => async (
  dispatch
) => {
  dispatch({ type: ArticleLogsTypes.ARTICLE_LOGS_LOADING });
  try {
    const { data } = await articleLogs({lesson});
    dispatch({
      type: ArticleLogsTypes.ARTICLE_LOGS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = !err?.response?.data?.message
      ? "Something went wrong"
      :err?.response?.data?.message;
    dispatch({
      type: ArticleLogsTypes.ARTICLE_LOGS_ERROR,
      payload: error,
    });
  }
};
