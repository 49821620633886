import { UpdateProgressTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  loading: false,
  update_progress_data: null,
};

const updateProgressReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UpdateProgressTypes.UPDATE_PROGRESS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UpdateProgressTypes.UPDATE_PROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        update_progress_data: action.payload,
      };
    case UpdateProgressTypes.UPDATE_PROGRESS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default updateProgressReducer;
