import { SubmitQuizAnswerTypes } from "./types";
import { submitQuizAnswer } from "../../../api/index";

export const submitQuizAnswerRequest = (lessonId, answers,history) => async (
  dispatch
) => {
  dispatch({ type: SubmitQuizAnswerTypes.SUBMIT_QUIZ_ANSWER_LOADING });
  try {
    console.log(answers,"obj")
    const { data } = await submitQuizAnswer(lessonId, answers);
    const {score} =data;
    dispatch({
      type: SubmitQuizAnswerTypes.SUBMIT_QUIZ_ANSWER_SUCCESS,
      payload: score,
    });
    history.push(`/quiz-result/${lessonId}`)
  } catch (err) {
    console.log(`errorrr`, err?.response?.data?.message);
    const error = !err?.response?.data?.message
      ? "Something went wrong"
      :err?.response?.data?.message;
    dispatch({
      type: SubmitQuizAnswerTypes.SUBMIT_QUIZ_ANSWER_ERROR,
      payload: error,
    });
  }
};
