import { GetCourseCategoryTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  loading: false,
  data: null,
};

const getCourseCategoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GetCourseCategoryTypes.GET_COURSES_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GetCourseCategoryTypes.GET_COURSES_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GetCourseCategoryTypes.GET_COURSES_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default getCourseCategoryReducer;
