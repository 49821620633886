import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Signup from "../Pages/Signup/Signup";
import CourseIntro from "../Pages/CourseIntroduction/CourseIntroduction";
import QuizResult from "../Pages/QuizResult/QuizResult";
import Congratulations from "../Pages/Congratulations/Congratulations";
import NotFound from "../Components/404/NotFound";
import Error_Confirm from "../Components/Error_Confirm/Error_Confirm";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import LessonsMiddleware from "../Pages/LessonsMiddleware/LessonsMiddleware";
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PublicRoute from './PublicRoute/PublicRoute';
import Curriculums from "../Pages/Curriculums/Curriculums";

const AppRoutes = () => {
  return (
    <Router>
      <Switch>   
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute exact path="/sign-up" component={Signup} />
        <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
        <PublicRoute exact path="/reset-password" component={ResetPassword} />
        <PrivateRoute exact path="/course-introduction" component={CourseIntro} />
        <PrivateRoute exact path="/lesson/:id" component={LessonsMiddleware} />
        <PrivateRoute exact path="/quiz-result/:id" component={QuizResult} />
        <PrivateRoute exact path="/congratulations" component={Congratulations} />
        <PublicRoute exact path="/error" component={Error_Confirm} />
        <PrivateRoute exact path="/curriculum" component={Curriculums} />
        <PublicRoute component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
