import React, { useState, useEffect } from "react";
import styles from "./lessonVideo.module.scss";
import SideNav from "../../Components/Shared/SideNav/SideNav";
import Header from "../../Components/Shared/Header/Header";
import TopButtons from "../../Components/TopButtons/TopButtons";
import ReactPlayer from "react-player";
import MobileHeader from "../../Components/Shared/MobileHeader/MobileHeader";
import { ReactComponent as Hamburger } from "../../Assets/hamburger.svg";
import { Row, Col, Button } from "reactstrap";
import Markdown from "markdown-to-jsx";
import Footer from "../../Components/Footer/Footer";
import "./override.scss";
import ExpertQuestion from "../../Components/ExpertQuestion/ExpertQuestion";
import { submitVideoLogs } from "../../redux/logs/video-logs/actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { clock, getSeconds, getMyTimer, setSeconds } from "./service";
const LessonVideo = ({ data }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    clearInterval(getMyTimer());
    if (getSeconds() != 0) {
      dispatch(submitVideoLogs(data.id, getSeconds()));
    }
    setSeconds(0);
  }, [location]);

  return (
    <>
      <Header />
      <MobileHeader hideLogo={true} />

      <Row className={styles.main} noGutters>
        <Col lg={9} md={9} sm={12} className={styles.course}>
          <>
            <div className={styles.backArrowContainer}>
              <Hamburger
                className={styles.icon}
                onClick={() => setMobile(true)}
              />
            </div>
            <TopButtons
              courseTitle={data.title}
              lesson={''}
              Data={data}
            />

            {data.material.map((item, i) => {
              return (
                <div className={styles.frame}>
                  <ReactPlayer
                    className="react-player"
                    url={item?.video.url}
                    playing={true}
                    controls={true}
                    light={item.thumbnail.url}
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    onPlay={clock}
                    onPause={(play) => clearInterval(getMyTimer())}
                    width="100%"
                    height="100%"
                  />
                  {item.speaker == null ? null : (
                    <Button
                      className={`${styles.courseDivButton2} mt-4`}
                      onClick={(e) => setModal(true)}
                    >
                      {`Ask ${item.speaker}`}
                    </Button>
                  )}
                </div>
              );
            })}

            <div className={styles.about}>
              <Markdown options={{ wrapper: "article" }}>
                {data?.Description}
              </Markdown>
            </div>
          </>
        </Col>

        <Col lg={3} md={3} sm={12} className={styles.main2}>
          <SideNav mobile={mobile} setMobile={setMobile} id={data.id} />
        </Col>
      </Row>
      <ExpertQuestion
        modal={modal}
        setModal={setModal}
        id={data.id}
        professorName={data.material[0].speaker}
      />
      <Footer />
    </>
  );
};

export default LessonVideo;
