import React, { useState, useEffect } from "react";
import styles from "./SideNav.module.scss";
import { ReactComponent as Cross } from "../../../Assets/SideNav/cross.svg";
import { useSelector, useDispatch } from "react-redux";
import { GetMyCoursesRequest } from "../../../redux/courses/get-my-courses/actions";
import { useHistory } from "react-router-dom";
import { ReactComponent as Play } from "../../../Assets/SideNav/play.svg";
import { ReactComponent as Tick } from "../../../Assets/SideNav/tick.svg";
import { GetMyProgressRequest } from "../../../redux/courses/get-my-progress/actions";

function SideNav({ mobile, setMobile, id }) {
  const { data, loading, error } = useSelector(
    (state) => state.getMyCoursesReducer
  );
  const { progress_data, progress_loading } = useSelector(
    (state) => state.getMyprogressReducer
  );

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetMyCoursesRequest());
    dispatch(GetMyProgressRequest());
  }, []);
 

  return (
    <div
      className={`${styles.mainContainer} ${
        mobile ? styles.mainContainerActive : null
      } `}
    >
      <Cross className={styles.icon} onClick={() => setMobile(false)} />
      {data
        ? data.modules.map((module, i) => {
            return (
              <div key={i}>
                <div className={styles.chapterContainer}>
                  <p>
                    {i+1}. {module.title}
                  </p>
                </div>
                {module.lessons.map((lesson, j) => {
                  return (
                    <div
                      key={j}
                      className={
                        id === lesson.id
                          ? styles.selectedLessonMainContainer
                          : styles.lessonMainContainer
                      }
                      onClick={() => {
                        history.push(`/lesson/${lesson.id}`);
                      }}
                    >
                      <div className={styles.lessonContainer}>
                        <div className={styles.lessonCheck}>
                          {id === lesson.id ? (
                            <Play
                              width="100%"
                              height="100%"
                              className={styles.playIcon}
                            />
                          ) : (
                            progress_data &&
                            progress_data.progress.finishedLessons.map(
                              (lessonId, i) => {
                                return (
                                  lessonId === lesson.id && (
                                    <Tick
                                      width="100%"
                                      height="100%"
                                      className={styles.playIcon}
                                    />
                                  )
                                );
                              }
                            )
                          )}
                        </div>
                        <div className={styles.lesson}>
                          <div>
                            <div className={styles.lessonNumber}>
                              <p>Lesson {j+1}</p>
                            </div>
                            <div className={styles.lessonName}>
                              <p>{lesson.title}</p>
                            </div>
                          </div>
                          {/* <div className={styles.lessonTimer}>
                            <p>3:10</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        : null}
    </div>
  );
}

export default SideNav;
