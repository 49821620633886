import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    //Apply to every request

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //Save to local storage
    localStorage.setItem("token", token);
  } else {
    //delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
