import { ForgotPasswordTypes } from "./types";
import { forgotPassword } from "../../../api/index";

export const ForgotPasswordRequest = (credentials, history) => async (
  dispatch
) => {
  dispatch({ type: ForgotPasswordTypes.FORGOT_PASSWORD_LOADING });
  try {
    const { data } = await forgotPassword(credentials);
    const { email } = credentials;
    dispatch({
      type: ForgotPasswordTypes.FORGOT_PASSWORD_SUCCESS,
      payload: `A link has been sent to ${email}, please check your email to complete the proccess.`,
    });
 
  } catch (err) {
    const error = !err.response.data.message[0].messages
      ? history.push("/error")
      : err.response.data.message[0].messages;
    dispatch({
      type: ForgotPasswordTypes.FORGOT_PASSWORD_ERROR,
      payload: error,
    });
  }
};
