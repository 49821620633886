import React from "react";
import AppRoutes from "./AppRoutes/AppRoutes";
import "./App.css";
import axios from "axios";
import { positions, Provider as AlertProvider } from "react-alert";
function App() {
  const options = {
    timeout: 5000,
    position: positions.TOP_CENTER,
  };
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
  return (
    <>
    <div className="parent_container">
      <AppRoutes />
      </div>
    </>
  );
}

export default App;
