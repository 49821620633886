import { ForgotPasswordTypes } from "./types";

const INITIAL_STATE = {
  error:null,
  loading:false,
  data:null,
};

const forgotPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ForgotPasswordTypes.FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ForgotPasswordTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
        

      };
    case ForgotPasswordTypes.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default forgotPasswordReducer;
