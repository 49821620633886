import { UpdateProgressTypes } from "./types";
import { updateProgress } from "../../../api/index";
import { GetMyProgressRequest } from "../get-my-progress/actions";

export const UpdateProgressRequest =
  (lessonId, nextLesson, history) => async (dispatch) => {
    dispatch({ type: UpdateProgressTypes.UPDATE_PROGRESS_LOADING });
    try {
      const { data, status } = await updateProgress(lessonId);

      dispatch({
        type: UpdateProgressTypes.UPDATE_PROGRESS_SUCCESS,
        payload: data,
      });
      dispatch(GetMyProgressRequest());
      history.push(`/lesson/${nextLesson}`);
    } catch (err) {
      console.log(`errorrr`, err.response.data.message);
      const error = !err.response.data.message
        ? "Something went wrong"
        : err.response.data.message;
      dispatch({
        type: UpdateProgressTypes.UPDATE_PROGRESS_ERROR,
        payload: error,
      });
    }
  };
