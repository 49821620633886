import React, { useState } from "react";
import styles from "./LessonArticle.module.scss";
import SideNav from "../../Components/Shared/SideNav/SideNav";
import Header from "../../Components/Shared/Header/Header";
import { Col, Row } from "reactstrap";
import TopButtons from "../../Components/TopButtons/TopButtons";
import { ReactComponent as Hamburger } from "../../Assets/hamburger.svg";
import MobileHeader from "../../Components/Shared/MobileHeader/MobileHeader";
import Markdown from "markdown-to-jsx";
import Footer from "../../Components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { submitArticleLogs } from "../../redux/logs/article-logs/actions";
import Loader from "../../Components/Loader/Loader";

const LessonArticle = ({ data }) => {
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState(false);
  const { article_logs_error, article_logs_loading } = useSelector(
    (state) => state.articleLogsReducer
  );
  if (article_logs_loading) {
    return <Loader />;
  }
  return (
    <>
      <Header hideBackArrow={true} />
      <MobileHeader hideLogo={true} />
      <Row className={styles.main} noGutters>
        <Col lg={9} md={9} sm={12} className={styles.courseIntro}>
          <div className={styles.backArrowContainer}>
            <Hamburger
              className={styles.icon}
              onClick={() => setMobile(true)}
            />
          </div>
          <TopButtons
            courseTitle={data?.title}
            lesson={''}
            Data={data}
          />
          <Row className={styles.courseDi} noGutters>
            <Col lg={4} md={6} sm={12} className={styles.courseDiv1}>
              {data
                ? data.material.map((item, i) => {
                    return <img src={item.thumbnail.url} alt="logo" />;
                  })
                : null}
            </Col>
            <Col lg={8} md={6} sm={12} className={styles.courseDiv2}>
              <Markdown options={{ wrapper: "article" }}>
                {data?.Description}
              </Markdown>
              {data
                ? data.material.map((item, i) => {
                    return (
                      <a key={i} href={item.article.url} target="_blank">
                        <button
                          onClick={(e) => dispatch(submitArticleLogs(data?.id))}
                        >
                          Read The Article
                        </button>
                      </a>
                    );
                  })
                : null}
            </Col>
          </Row>
        </Col>

        <Col lg={3} md={3} sm={12} className={styles.main2}>
          <SideNav mobile={mobile} setMobile={setMobile} id={data.id} />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default LessonArticle;
