import { VideoLogsTypes } from "./types";
import { videoLogs } from "../../../api/index";

export const submitVideoLogs = (lesson, secondsWatched) => async (dispatch) => {
  dispatch({ type: VideoLogsTypes.VIDEO_LOG_LOADING });
  try {
    const { data } = await videoLogs({ lesson, secondsWatched });
    dispatch({
      type: VideoLogsTypes.VIDEO_LOG_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = !err?.response?.data?.message
      ? "Something went wrong"
      : err?.response?.data?.message;
    dispatch({
      type: VideoLogsTypes.VIDEO_LOG_ERROR,
      payload: error,
    });
  }
};

