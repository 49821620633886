import React, { useState, useEffect } from "react";
import styles from "./CountdownTimer.module.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";
function CountdownTimer({ date, history, title }) {
  const [Duration, setDuration] = useState(null);
  const timer = () => {
    const now = new Date();
    const then = date;
    const ms = moment(then).diff(moment(now));
    const d = moment.duration(ms);
    const { _data } = d;
    setDuration(_data);
  };
  useEffect(() => {
    timer();
    if (
      Duration?.days == 0 &&
      Duration?.hours == 0 &&
      Duration?.minutes == 0 &&
      Duration?.seconds == 0
    ) {
      history.go(0);
    }
  }, [Duration]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.countdownContainer}>
        <div className={styles.headingContainer}>
          <p>Coming Soon</p>
        </div>
        <div className={styles.countdown}>
          <div className={styles.secondsContainer}>
            <div className={styles.seconds}>
              {Duration && Duration.days != null ? (
                <div className={styles.first}>
                  <p>{Duration.days}</p>
                </div>
              ) : null}
            </div>
            <div className={styles.heading}>
              <p>Days</p>
            </div>
          </div>
          <div className={styles.hourContainer}>
            <div className={styles.hour}>
              {Duration && Duration.hours != null ? (
                <div className={styles.first}>
                  <p>{Duration.hours}</p>
                </div>
              ) : null}
            </div>
            <div className={styles.heading}>
              <p>Hour</p>
            </div>
          </div>
          <div className={styles.minutesContainer}>
            <div className={styles.minutes}>
              {Duration && Duration.minutes != null ? (
                <div className={styles.first}>
                  <p>{Duration.minutes}</p>
                </div>
              ) : null}
            </div>
            <div className={styles.heading}>
              <p>Minutes</p>
            </div>
          </div>
          <div className={styles.secondsContainer}>
            <div className={styles.seconds}>
              {Duration && Duration.seconds != null ? (
                <div className={styles.first}>
                  <p>{Duration.seconds}</p>
                </div>
              ) : null}
            </div>
            <div className={styles.heading}>
              <p>Seconds</p>
            </div>
          </div>
        </div>
        <div className={styles.descriptionContainer}>
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
}

export default CountdownTimer;
