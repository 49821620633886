import { ExpertQuestionTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  loading: false,
  success: null,
};

const expertQuestionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ExpertQuestionTypes.EXPERT_QUESTION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ExpertQuestionTypes.EXPERT_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };
    case ExpertQuestionTypes.EXPERT_QUESTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default expertQuestionReducer;
