import axios from "axios";
import { URL } from "../config/serverUrl";

// *** Auth Flow ***
// Register
export const register = (data) =>
  axios.post(`${URL}/auth/local/register`, data);

//  Login
export const login = (data) => axios.post(`${URL}/auth/local/`, data);

// Get country and specialites
export const getAppInfo = () => axios.get(`${URL}/app-info`);

// Forgot password request

export const forgotPassword = (data) =>
  axios.post(`${URL}/auth/forgot-password`, data);

// Reset password

export const ResetPassword = (data) =>
  axios.post(`${URL}/auth/reset-password`, data);

export const getProfile = () => axios.get(`${URL}/users/profile`);

// *** Courses ***

export const getMyCourses = () => axios.get(`${URL}/courses/me`);

export const getMyProgress = () => axios.get(`${URL}/user-progresses/me`);

export const updateProgress = (data) =>
  axios.post(`${URL}/user-progresses/me`, data);

// *** Lessons ***

export const getLessonDetails = (id) => axios.get(`${URL}/lessons/${id}`);

export const submitQuizAnswer = (id, data) =>
  axios.post(`${URL}/lessons/${id}/quiz-answer`, data);

export const getLessonUploads = (data) =>
  axios.get(`${URL}/lessons/${data}/user-uploads`);

export const submitLessonUploads = (id, data) =>
  axios.post(`${URL}/lessons/${id}/user-uploads`, data);

export const uploadFiles = (formData) =>
  axios.post(`${URL}/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// *** Lesson Logs ***

export const articleLogs = (data) => axios.post(`${URL}/articles-logs`, data);
export const videoLogs = (data) => axios.post(`${URL}/watch-logs`, data);

export const expertQuestion = (data) =>
  axios.post(`${URL}/expert-questions`, data);
//course-categories
export const getCourseCategories = () => axios.get(`${URL}/course-categories`);

export const startCourse = (id) => axios.post(`${URL}/courses/${id}/start`);
