import { LoginTypes } from "./types";
import setAuthToken from "../../../config/setAuthToken";
import { login } from "../../../api/index";

export const Signin = (credentails, history) => async (dispatch) => {
    dispatch({ type: LoginTypes.LOGIN_LOADING });
    try {
      const { data } = await login(credentails);
      const { jwt, user } = data;
  
      dispatch({
        type: LoginTypes.LOGIN_SUCCESS,
        payload: { jwt, user },
      });
      //  set token to auth header
      setAuthToken(jwt);
      history.push("/course-introduction")
    } catch (err) {
      console.log(`errorrr`, err.response);
      const error = !err.response.data.message[0].messages
        ? history.push("/error")
        : err.response.data.message[0].messages;
      dispatch({
        type: LoginTypes.LOGIN_ERROR,
        payload: error,
      });
    }
  };