import { GetLessonDetailsTypes } from "./types";
import { getLessonDetails } from "../../../api/index";

export const getLessonDetailsRequest = (lessonId) => async (dispatch) => {
  dispatch({ type: GetLessonDetailsTypes.GET_LESSON_DETAILS_LOADING });
  try {
    const { data } = await getLessonDetails(lessonId);
    dispatch({
      type: GetLessonDetailsTypes.GET_LESSON_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = !err?.response?.data?.message
      ? "Something went wrong"
      : err.response.data.message;
    dispatch({
      type: GetLessonDetailsTypes.GET_LESSON_DETAILS_ERROR,
      payload: error,
    });
  }
};
