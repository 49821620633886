import { ProfileActionTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  loading: false,
  data: null,
};

const getProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProfileActionTypes.GET_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ProfileActionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ProfileActionTypes.GET_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default getProfileReducer;
