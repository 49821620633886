import { ResetPasswwordTypes } from "./types";

const INITIAL_STATE = {
  error:null,
  loading:false,
  token: "",
  userData: null,
};

const resetPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ResetPasswwordTypes.RESET_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ResetPasswwordTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.jwt,
        userData: action.payload.user,

      };
    case ResetPasswwordTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default resetPasswordReducer;
