import React, { useState } from "react";
import styles from "./LessonQuiz.module.scss";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import { FormGroup, Label, Input } from "reactstrap";
import SideNav from "../../Components/Shared/SideNav/SideNav";
import { Progress } from "reactstrap";
import Header from "../../Components/Shared/Header/Header";
import TopButtons from "../../Components/TopButtons/TopButtons";
import { Col, Row } from "reactstrap";
import MobileHeader from "../../Components/Shared/MobileHeader/MobileHeader";
import { ReactComponent as Hamburger } from "../../Assets/hamburger.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitQuizAnswerRequest } from "../../redux/lessons/submit-quiz-answer/actions";
import Loader from "../../Components/Loader/Loader";
import { Alert } from "reactstrap";
import Footer from "../../Components/Footer/Footer";

const LessonQuiz = ({ data }) => {
  const [mobile, setMobile] = useState(false);
  const [answers, setAnswers] = useState({});
  const [quizCount, setQuizCount] = useState(0);
  const [selected, setSelected] = useState("");
  const [fieldError, setFeildError] = useState("");
  const [progressValue, setProgressValue] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();
  const { questions } = data?.material[0];
  const { loading, error } = useSelector(
    (state) => state.submitQuizAnswerReducer
  );

  const submitQuizHandler = () => {
    dispatch(submitQuizAnswerRequest(data.id, { answers }, history));
  };
  const selectAnswer = (questionId, answer) => {
    setSelected(answer);
    setAnswers({
      ...answers,
      [questionId]: answer,
    });
  };
  const nextQuestion = (questionId) => {
    if (questionId in answers) {
      if (quizCount + 1 < questions.length) {
        setQuizCount(quizCount + 1);
        if (progressValue == 0) {
          setProgressValue(100 / questions.length);
        } else {
          setProgressValue(progressValue * 2);
        }
      } else {
        submitQuizHandler();
        setFeildError("");
      }
      setFeildError("");
    } else {
      setFeildError("Please Select any option");
    }
  };
  const previousQuestion = () => {
    if (quizCount > 0) {
      setQuizCount(quizCount - 1);
      if (progressValue===100 / questions.length) {
        setProgressValue(0);
      } else {
        setProgressValue(progressValue / 2);
      }
      console.log((progressValue / questions.length) * 2);
    }
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header hideBackArrow={true} />
      <MobileHeader hideLogo={true} />
      <Row className={styles.main} noGutters>
        <Col lg={9} md={9} sm={12} className={styles.courseIntro}>
          <div className={styles.backArrowContainer}>
            <Hamburger
              className={styles.icon}
              onClick={() => setMobile(true)}
            />
          </div>

          <TopButtons
            courseTitle={data?.title}
            lesson={''}
            nextLesson={false}
            Data={data}
          />
          <div className={styles.courseDi}>
            {fieldError ? <Alert color="danger">{fieldError}</Alert> : null}
            <div className={styles.courseDiv2Text}>
              <span>{`Question # ${quizCount + 1} / ${questions.length}`}</span>
              <h6>{questions[quizCount]?.title}</h6>
            </div>
            <Row className={styles.courseDiv2_3} noGutters>
              {questions[quizCount]?.options.map((item, i) => (
                <Col lg={12} md={12} sm={12}>
                  {questions[quizCount]?.type == "mcq" ? (
                    <section
                      onClick={() =>
                        selectAnswer(questions[quizCount]?.id, item.id)
                      }
                      className={
                        selected == item.id
                          ? styles.select_true
                          : styles.select_false
                      }
                    >
                      <strong>{i + 1}</strong>
                      <p>{item.option}</p>
                    </section>
                  ) : null}
                </Col>
              ))}
              {questions[quizCount]?.type == "open" ? (
                <FormGroup className={styles.open_ended_container}>
                  <Label for="exampleText">Type your answer</Label>
                  <Input
                    type="textarea"
                    name="openEnded"
                    id="exampleText"
                    onChange={(e) =>
                      selectAnswer(questions[quizCount]?.id, e.target.value)
                    }
                    placeholder="type your answer"
                    className={styles.open_ended_textbox}
                  />
                </FormGroup>
              ) : null}
            </Row>
          </div>

          <Row className={styles.courseDiv4} noGutters>
            <Col lg={1} md={1} sm={12}>
              <Button
                className={styles.courseDivButton1}
                onClick={(e) => previousQuestion(questions[quizCount]?.id)}
              >
                <ArrowBackIosIcon />
              </Button>
            </Col>
            <Col lg={8} md={8} sm={12}>
              <Progress className={styles.bar} value={progressValue} />
            </Col>
            <Col lg={3} md={3} sm={12}>
              <Button
                className={styles.courseDivButton2}
                endIcon={<SkipNextIcon />}
                onClick={(e) => nextQuestion(questions[quizCount]?.id)}
              >
                Next Question
              </Button>
            </Col>
          </Row>
        </Col>

        <Col lg={3} md={3} sm={12} className={styles.main2}>
          <SideNav mobile={mobile} setMobile={setMobile} id={data.id} />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default LessonQuiz;
