import React, { useState } from "react";
import styles from "./forgotPassword.module.scss";
import bg from "../../Assets/bg.svg";
import Logo from "../../Assets/extra/MASTERS LOGO-02.svg";
import { Link } from "react-router-dom";
import BackArrow from "../../Assets/Header/back-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ForgotPasswordRequest } from "../../redux/auth/forgot-password/actions";
import { Alert } from "reactstrap";
import Loader from "../../Components/Loader/Loader";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [credentials, setCredentials] = useState({
    email: "",
  });
  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };
  // Accessing forgot password reducer
  const { error, loading, data } = useSelector(
    (state) => state.forgotPasswordReducer
  );
  // final hitting of forgot password api

  const forgotPassword = () => {
    dispatch(ForgotPasswordRequest(credentials, history));
  };
  //  will load screen when api hits

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles.Forgot}>
      <div className={styles.div1}>
        <div className={styles.picture}>
          <img src={bg} alt="pic" />
          <h4>
            Welcome to the MASTERS Program!
            <br/>
            Your simple & reliable advanced courses platform
          </h4>
        </div>
        <div className={styles.div1Logo}>
          <img src={Logo} alt="pic" />
        </div>
      </div>
      <div className={styles.div2}>
        <div className={styles.right}>
          <div className={styles.headings}>
            <section>
            
              <h3>Forgot Password</h3>
              <div className="mt-1">
                {error &&
                  error.map((err) => (
                    <Alert color="danger">{err.message}</Alert>
                  ))}
              </div>

              <div className="mt-1">
                {data && <Alert color="success">{data}</Alert>}
              </div>
            </section>
            <section>
              <p>
                Enter your email address below. We wil send you an SMS with a
                join code to confirm your identity.
              </p>
            </section>
          </div>
          <div className={styles.mobile}>
            <section>
              <label>Email Address</label>
              <input
                type="email"
                name="email"
                placeholder="Enter email address"
                className={styles.email}
                onChange={(e) => handleChange(e)}
              />
            </section>

            <section className={styles.button}>
              <button onClick={forgotPassword}>Send Link</button>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
