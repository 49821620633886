import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLessonDetailsRequest } from "../../redux/lessons/get-lesson-details/actions";
import Loader from "../../Components/Loader/Loader";
import LessonUpload from "../LessonUpload/LessonUpload";
import LessonQuiz from "../LessonQuiz/LessonQuiz";
import LessonArticle from "../LessonArticle/LessonArticle";
import LessonStreaming from "../LessonStreaming/LessonStreaming";
import LessonVideo from "../LessonVideo/LessonVideo";
import { useHistory } from "react-router-dom";

function LessonsMiddleware() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, loading, error } = useSelector(
    (state) => state.getLessonDetailsReducer
  );

  const type = data?.type;

  useEffect(() => {
    dispatch(getLessonDetailsRequest(id));
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {type === "video" && <LessonVideo data={data} />}
      {type === "article" && <LessonArticle data={data} />}
      {type === "live" && <LessonStreaming data={data} history={history} />}
      {type === "upload" && <LessonUpload data={data} />}
      {type === "quiz" && <LessonQuiz data={data} />}
    </>
  );
}

export default LessonsMiddleware;
