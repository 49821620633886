import { ResetPasswwordTypes } from "./types";
import { ResetPassword } from "../../../api/index";
import setAuthToken from "../../../config/setAuthToken";

export const resetPassword = (passwordField, history) => async (dispatch) => {
  dispatch({ type: ResetPasswwordTypes.RESET_PASSWORD_LOADING });
  try {
    const { data } = await ResetPassword(passwordField);
    const { jwt, user } = data;
    dispatch({
      type: ResetPasswwordTypes.RESET_PASSWORD_SUCCESS,
      payload: { jwt, user },
    });
     //  set token to auth header
     setAuthToken(jwt);
    history.push("/course-introduction")
     
  } catch (err) {
    const error = !err.response.data.message[0].messages
      ? history.push("/error")
      : err.response.data.message[0].messages;

    dispatch({
      type: ResetPasswwordTypes.RESET_PASSWORD_ERROR,
      payload: error,
    });
  }
};
