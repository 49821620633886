var playedSeconds = 0;
var myTimer;
export const clock = () => {
    myTimer = setInterval(() => {
        playedSeconds = ++playedSeconds;
      }, 1000);
};
 export const getSeconds=()=>{
     return playedSeconds;
 }
 export const getMyTimer=()=>{
    return myTimer;
}
export const setSeconds=(seconds)=>{
    playedSeconds=seconds;
}