import { AppInfoTypes } from "./types";


const INITIAL_STATE = {
 error:null,
 loading:false,
 specialities:null,
 countries:null
};

const appInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppInfoTypes.APP_INFO_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AppInfoTypes.APP_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        specialities: action.payload.specialities,
        countries: action.payload.countries,

      };
    case AppInfoTypes.APP_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default appInfoReducer;
