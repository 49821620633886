import { UploadFilesTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  loading: false,
  success: null,
};

const uploadFilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UploadFilesTypes.UPLOAD_FILES_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UploadFilesTypes.UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };
    case UploadFilesTypes.UPLOAD_FILES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default uploadFilesReducer;
