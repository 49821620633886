import { VideoLogsTypes } from "./types";

const INITIAL_STATE = {
  video_logs_error: null,
  video_logs_loading: false,
  video_logs_success: null,
};

const videoLogsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
   
    case VideoLogsTypes.VIDEO_LOG_LOADING:
      return {
        ...state,
        video_logs_loading: true,
        video_logs_error: null,
      };
    case VideoLogsTypes.VIDEO_LOG_SUCCESS:
      return {
        ...state,
        video_logs_loading: false,
        video_logs_success: action.payload,
      };
    case VideoLogsTypes.VIDEO_LOG_ERROR:
      return {
        ...state,
        video_logs_loading: false,
        video_logs_error: action.payload,
      };

    default:
      return state;
  }
};

export default videoLogsReducer;
