import { GetLessonUploadsTypes } from "./types";

const INITIAL_STATE = {
  errors: null,
  loadings: false,
  uploads: null,
};

const getLessonUplaodsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GetLessonUploadsTypes.GET_LESSON_UPLOADS_LOADING:
      return {
        ...state,
        loadings: true,
        errors: null,
      };
    case GetLessonUploadsTypes.GET_LESSON_UPLOADS_SUCCESS:
      return {
        ...state,
        loadings: false,
        uploads: action.payload,
      };
    case GetLessonUploadsTypes.GET_LESSON_UPLOADS_ERROR:
      return {
        ...state,
        loadings: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default getLessonUplaodsReducer;
