import React, { useState, useEffect } from "react";
import styles from "./Login.module.scss";
import bg from "../../Assets/bg.svg";
import Logo from "../../Assets/extra/MASTERS LOGO-02.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Signin } from "../../redux/auth/login/actions";
import { Alert } from "reactstrap";
import Loader from "../../Components/Loader/Loader";
const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [fieldError, setFieldError] = useState("");
  const [credentials, setCredentials] = useState({
    identifier: "",
    password: "",
  });
  // Accessing login reducer

  const { error, loading } = useSelector((state) => state.loginReducer);
  const [passwordShown, setPasswordShown] = useState(false);
  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  // final hitting of login api

  const login = () => {
    dispatch(Signin(credentials, history));
  };
  //  will load screen when api hits

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles.login}>
      <div className={styles.div1}>
        <div className={styles.picture}>
          <img src={bg} alt="pic" />
          <h4>
            Welcome to the MASTERS Program!
            <br/>
            Your simple & reliable advanced courses platform
          </h4>
        </div>
        <div className={styles.div1Logo}>
          <img src={Logo} alt="pic" />
        </div>
      </div>
      <div className={styles.div2}>
        <div className={styles.right}>
          <div className={styles.headings}>
            <section>
              <h2>
                Welcome To Type-2 <br /> Masterclass
              </h2>
            </section>
            <div>
              {error &&
                error.map((err) => <Alert color="danger">{err.message}</Alert>)}
            </div>
            <section>
              <p>First login your account</p>
            </section>
          </div>
          <div className={styles.mobile}>
            <section>
              <label>Your Email</label>
              <input
                type="text"
                placeholder="Enter Your Email"
                name="identifier"
                onChange={(e) => handleChange(e)}
                className={styles.email}
              />
            </section>
            <section>
              <section className={styles.passwordLabel}>
                <p>
                  Password{" "}
                  <Link to="/forgot-password">
                    <span>Forgot Password</span>
                  </Link>
                </p>
              </section>
              <div className={styles.input_icon_wrap}>
                <input
                  placeholder="Enter Password"
                  name="password"
                  onChange={(e) => handleChange(e)}
                  type={passwordShown ? "text" : "password"}
                  className={styles.input_with_icon}
                />
                <span className={styles.input_icon}>
                  <span
                    onClick={togglePasswordVisiblity}
                    class={
                      passwordShown == true ? "far fa-eye" : "far fa-eye-slash"
                    }
                  ></span>
                </span>
              </div>
            </section>
            <section className={styles.button}>
              <button onClick={login}>Login</button>
            </section>
            <div className={styles.or}>
              <p>OR</p>
              <div></div>
            </div>
            <div className={styles.mobileSignup}>
              <p>
                Don’t have an account? <Link to="/sign-up">Sign up</Link>
              </p>
            </div>
            <section className={styles.already}>
              <span>Don't have an account, </span>{" "}
              <Link to="/sign-up" className={styles.link}>
                Sign up here
              </Link>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
