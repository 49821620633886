import { GetLessonUploadsTypes } from "./types";
import { getLessonUploads } from "../../../api/index";

export const getLessonUploadsRequest = (lessonId) => async (dispatch) => {
  dispatch({ type: GetLessonUploadsTypes.GET_LESSON_UPLOADS_LOADING });
  try {
    const { data } = await getLessonUploads(lessonId);
    dispatch({
      type: GetLessonUploadsTypes.GET_LESSON_UPLOADS_SUCCESS,
      payload: data?.uploads.reverse(),
    });
  } catch (err) {
    console.log(`errorrr`, err.response.data.message);
    const error = !err.response.data.message
      ? "Something went wrong"
      : err.response.data.message;
    dispatch({
      type: GetLessonUploadsTypes.GET_LESSON_UPLOADS_ERROR,
      payload: error,
    });
  }
};
