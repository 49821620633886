import { GetCourseCategoryTypes } from "./types";
import { getCourseCategories } from "../../../api/index";

export const GetCourseCategory = () => async (dispatch) => {
  dispatch({ type: GetCourseCategoryTypes.GET_COURSES_CATEGORY_LOADING });
  try {
    const { data } = await getCourseCategories();
    dispatch({
      type: GetCourseCategoryTypes.GET_COURSES_CATEGORY_SUCCESS,
      payload: data,
    });
    // history.push("/forgot1");
  } catch (err) {
    console.log(`errorrr`, err.response.data.message);
    const error = !err.response.data.message
      ? "Something went wrong"
      : err.response.data.message;
    dispatch({
      type: GetCourseCategoryTypes.GET_COURSES_CATEGORY_ERROR,
      payload: error,
    });
  }
};
