import { ActionTypes } from "./types";
import { startCourse } from "../../../api/index";
import { Error } from "../../../Responses/Response";
import { lessonFilter } from "../../../Pages/CourseIntroduction/CourseIntroduction";
import { SetCourse } from "../get-my-courses/actions";
export const StartCourse = (lessonId, history) => async (dispatch) => {
  dispatch({ type: ActionTypes.LOADING });
  try {
    const { data, status } = await startCourse(lessonId);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 200) {
      dispatch(SetCourse(data));
      let filteredLessons = lessonFilter(data);
      history.push(`/lesson/${filteredLessons[0]}`);
    }
    console.log(data);
  } catch (err) {
    const error = !err.response.data.message
      ? "Something went wrong"
      : err.response.data.message;
    Error(error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
