import React, { useEffect, useState } from "react";
import styles from "./quizResult.module.scss";
import SideNav from "../../Components/Shared/SideNav/SideNav";
import { Progress } from "reactstrap";
import { getLessonDetailsRequest } from "../../redux/lessons/get-lesson-details/actions";
import Header from "../../Components/Shared/Header/Header";
import TopButtons from "../../Components/TopButtons/TopButtons";
import Loader from "../../Components/Loader/Loader";
import { Col, Row } from "reactstrap";
import MobileHeader from "../../Components/Shared/MobileHeader/MobileHeader";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Hamburger } from "../../Assets/hamburger.svg";
import { useHistory } from "react-router-dom";
import Footer from '../../Components/Footer/Footer'

const QuizResult = () => {
  const [mobile, setMobile] = useState(false);
  const dispatch = useDispatch();
  let { id } = useParams();

  const history = useHistory();
  const { score } = useSelector((state) => state.submitQuizAnswerReducer);
  const { data, loading, error } = useSelector(
    (state) => state.getLessonDetailsReducer
  );

  useEffect(() => {
    dispatch(getLessonDetailsRequest(id));
  }, [id]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Header hideBackArrow={true} />
      <MobileHeader hideLogo={true} />

      <Row className={styles.main} noGutters>
        <Col lg={9} md={9} sm={12} className={styles.courseIntro}>
        <div className={styles.backArrowContainer}>
            <Hamburger
              className={styles.icon}
              onClick={() => setMobile(true)}
            />
            </div>
          <TopButtons
            courseTitle={"Course Quiz"}
            lesson={""}
            nextLesson={true}
            Data={data}
          />
          <Row className={styles.courseDi} noGutters>
            <div className={styles.courseDiv2Text}>
              <h5>{`You have a done great job.`}</h5>
              <h6>{`Your Score is ${score}`}</h6>
            </div>

            <div className={styles.courseDiv4}>
              <Progress className={styles.bar} value="100" />
            </div>
          </Row>
        </Col>

        <Col lg={3} md={3} sm={12} className={styles.main2}>
          <SideNav mobile={mobile} setMobile={setMobile} id={data?.id} />
        </Col>
      </Row>
      <Footer/>
    </>
  );
};

export default QuizResult;
