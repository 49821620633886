import React, { useState, useEffect, useCallback } from "react";
import styles from "./LessonUpload.module.scss";
import DownPic from "../../Assets/down/page10.svg";
import DownPic2 from "../../Assets/down/page10_2.svg";
import cross from "../../Assets/down/cross.svg";
import SideNav from "../../Components/Shared/SideNav/SideNav";
import MobileHeader from "../../Components/Shared/MobileHeader/MobileHeader";
import { ReactComponent as Hamburger } from "../../Assets/hamburger.svg";
import Header from "../../Components/Shared/Header/Header";
import TopButtons from "../../Components/TopButtons/TopButtons";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { uploadFilesRequest } from "../../redux/lessons/upload-files/actions";
import { getLessonUploadsRequest } from "../../redux/lessons/get-lesson-uploads/actions";
import FormData from "form-data";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import Loader from "../../Components/Loader/Loader";
import { useDropzone } from "react-dropzone";
import Markdown from "markdown-to-jsx";
import { Col, Row } from "reactstrap";
import Footer from '../../Components/Footer/Footer'

const LessonUpload = ({ data }) => {
  const history = useHistory();
  const [mobile, setMobile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLessonUploadsRequest(data.id));
  }, []);
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const { loading, error, success } = useSelector(
    (state) => state.uploadFilesReducer
  );
  const { loadings, errors, uploads } = useSelector(
    (state) => state.getLessonUploadsReducer
  );

  const remove = (name) => {
    setFiles(files.filter((data) => data.name !== name));
  };
  const getPreviousUploadIds = () => {
    const ids = uploads?.map((upload) => upload.id);
    return ids;
  };
  const uploadToServer = async () => {
    if (files) {
      setIsUploading(true);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      dispatch(
        uploadFilesRequest(formData, data.id, history, getPreviousUploadIds())
      );
      dispatch(getLessonUploadsRequest(data.id));

      setIsUploading(false);
      setFiles(null);
    }
  };
  if (loading || loadings) {
    return <Loader />;
  }
  return (
    <>
      <Header hideBackArrow={true} />
      <MobileHeader hideLogo={true} />
      <Row className={styles.main} noGutters>
        <Col lg={9} md={9} sm={12} className={styles.courseIntro}>
          <div className={styles.backArrowContainer}>
            <Hamburger
              className={styles.icon}
              onClick={() => setMobile(true)}
            />
          </div>
          <TopButtons
            courseTitle={data?.title}
            lesson={''}
            Data={data}
          />

          <div className={styles.courseDi}>
            <div className={styles.courseDiv2}>
              {success ? <Alert color="success">{success}</Alert> : null}
              {data?.Description ? (
                <Markdown options={{ wrapper: "article" }}>
                  {data?.Description}
                </Markdown>
              ) : null}
            </div>
          </div>
          <div className={styles.courseDiv3}>
            <div className={styles.courseDiv3_sec1} {...getRootProps()}>
              <label for="files">
                <img src={DownPic} alt="downPic" />
              </label>
              <input {...getInputProps()} multiple />

              <p>Drag a file here or Browse for a file to upload</p>
              {files &&
                files.map((file) => (
                  <p className={styles.files}>{file.name}</p>
                ))}
            </div>

            <div className={styles.courseDiv3_sec2}>
              {uploads &&
                uploads.map((upload) => {
                  return (
                    <section>
                      <img src={DownPic2} alt="downPic2" />
                      <div className={styles.div3_title}>
                        <h6>{upload.name}</h6>
                        <span>{upload.size} KB</span>
                      </div>
                      {/* <strong>
                        <span onClick={() => remove(data.name)}>
                          <img src={cross} alt="cross" />
                        </span>
                      </strong> */}
                    </section>
                  );
                })}

              <Button className={styles.button} onClick={uploadToServer}>
                {" "}
                {isUploading ? "Uploading" : "Upload Now"}
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={3} md={3} sm={12} className={styles.main2}>
          <SideNav mobile={mobile} setMobile={setMobile} id={data?.id} />
        </Col>
      </Row>
      <Footer/>
    </>
  );
};

export default LessonUpload;
