import { LoginTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  loading: false,
  token: "",
  userData: null,
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoginTypes.LOGIN_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LoginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.jwt,
        userData: action.payload.user,
      };
    case LoginTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
