import { ArticleLogsTypes } from "./types";

const INITIAL_STATE = {
  article_logs_error: null,
  article_logs_loading: false,
  article_logs_success: null,
};

const articleLogsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ArticleLogsTypes.ARTICLE_LOGS_LOADING:
      return {
        ...state,
        article_logs_loading: true,
        article_logs_error: null,
      };
    case ArticleLogsTypes.ARTICLE_LOGS_SUCCESS:
      return {
        ...state,
        article_logs_loading: false,
        article_logs_success: action.payload,
      };
    case ArticleLogsTypes.ARTICLE_LOGS_ERROR:
      return {
        ...state,
        article_logs_loading: false,
        article_logs_error: action.payload,
      };

    default:
      return state;
  }
};

export default articleLogsReducer;
