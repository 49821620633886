import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import styles from "./ExpertQuestion.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { submitExpertQuestionRequest } from "../../redux/lessons/expert-question/actions";
import { Alert } from "reactstrap";
import Loader from "../../Components/Loader/Loader";

const ExpertQuestion = ({ modal, setModal, id, professorName }) => {
  const [subject, setSubject] = useState("");
  const [questionContent, setQuestionContent] = useState("");
  const [fieldError, setFieldError] = useState("");
  const { error, loading, success } = useSelector(
    (state) => state.expertQuestionReducer
  );
  const dispatch = useDispatch();
  const handleClick = () => {
    if (subject === "" || questionContent === "") {
      setFieldError("All fields are required!");
    } else {
      dispatch(submitExpertQuestionRequest(id, subject, questionContent));
    }
  };
  const toggle = () => setModal(!modal);
  if (loading) {
    return <Loader />;
  }
  return (
    <Modal
      style={{ backgroundColor: "transparent", top: "5rem" }}
      isOpen={modal}
      toggle={toggle}
    >
      <div className={styles.main_container}>
        {fieldError && (
          <Alert className="m-3" color="danger">
            {fieldError}
          </Alert>
        )}
        {success && (
          <Alert className="m-3" color="success">
            {success}
          </Alert>
        )}
        <div>
          {error &&
            error.map((err) => <Alert color="danger">{err.message}</Alert>)}
        </div>
        <h4>{`Ask ${professorName}`}</h4>
        <div className={styles.flexSection}>
          <section>
            <input
              type="text"
              placeholder="Subject"
              name="subject"
              onChange={(e) => {
                setSubject(e.target.value);
                setFieldError("");
              }}
            />
          </section>
          <section>
            <textarea
              type="textarea"
              placeholder="Question details"
              rows="3"
              name="questionDetails"
              onChange={(e) => {
                setQuestionContent(e.target.value);
                setFieldError("");
              }}
            />
          </section>
        </div>
        <Button className={styles.button_sent} onClick={handleClick}>
          Send
        </Button>
      </div>
    </Modal>
  );
};

export default ExpertQuestion;
