import React,{useEffect} from "react";
import styles from "./congratulations.module.scss";
import Header from "../../Components/Shared/Header/Header";
import {Button} from 'reactstrap'
import MobileHeader from "../../Components/Shared/MobileHeader/MobileHeader";
import { useSelector, useDispatch } from "react-redux";
import { GetMyCoursesRequest } from "../../redux/courses/get-my-courses/actions";
import Loader from "../../Components/Loader/Loader";
import Footer from '../../Components/Footer/Footer'

const Congratulations = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetMyCoursesRequest());
  }, []);
  const { data, loading, error } = useSelector(
    (state) => state.getMyCoursesReducer
  );
  if (loading) {
    return <Loader />;
  }
  return (
    <>
    <Header hideBackArrow={true} />
    <MobileHeader hideLogo={true} />

    <div className={styles.page13}>
      <div className={styles.bgImage}>
        <div className={styles.cong}>
        <p className={styles.greeting}>Congratulation</p>
        <p className={styles.certificateimg}>
        <img src={data?.logo?.url} alt="Certificate" className={styles.certificateimage}/>
        </p>
        </div>
        <p className={styles.status}>You have completed</p>
        <p className={styles.achievement}>{data?.title}</p>
        <p className={styles.subTitle}>We will send you the certificate shortly</p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Congratulations;
