import React, { useEffect } from "react";
import styles from "./header.module.scss";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import Arrow from "../../../Assets/Header/Arrow.svg";
import Profile from "../../../Assets/Header/Mask Group 1.svg";
import { useDispatch, useSelector } from "react-redux";
import { getProfileRequest } from "../../../redux/profile/actions";
import { useHistory } from "react-router-dom";
import axios from "axios";

const Header = ({course}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, loading } = useSelector((state) => state.profileReducer);
  const courseData = course ?? data?.course

  useEffect(() => {
    dispatch(getProfileRequest());
  }, []);
  const logout = () => {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
    history.push("/");
  };
  return (
    <div className={styles.mainContainer} >
      <div className={styles.logoContainer} onClick={e=>history.push("/course-introduction")}>
        <img width="100%" height="100%" src={courseData?.logoMobile?.url} alt="logo" />
      </div>

      <div className={styles.profileContainer}>
        <div className={styles.profileImage}>
          <img width="100%" height="100%" src={Profile} alt="profile" />
        </div>
        <div className={styles.profileName}>
          <p>
            {data?.firstName} {data?.lastName}
          </p>
        </div>
        <div className={styles.dropdownContainer}>
          <UncontrolledDropdown>
            <DropdownToggle className={styles.dropdown}>
              <img width="100%" height="100%" src={Arrow} alt="dropdown" />
            </DropdownToggle>
            <DropdownMenu right className={styles.dropdownMenu}>
              <DropdownItem onClick={logout} className={styles.dropdownItem}>
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
