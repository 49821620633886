import { GetMyProgressTypes } from "./types";

const INITIAL_STATE = {
  progress_error: null,
  progress_loading: false,
  progress_data: null,
};

const getMyProgressReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GetMyProgressTypes.GET_MY_PROGRESS_LOADING:
      return {
        ...state,
        progress_loading: true,
        progress_error: null,
      };
    case GetMyProgressTypes.GET_MY_PROGRESS_SUCCESS:
      return {
        ...state,
        progress_loading: false,
        progress_data: action.payload,
      };
    case GetMyProgressTypes.GET_MY_PROGRESS_ERROR:
      return {
        ...state,
        progress_loading: false,
        progress_error: action.payload,
      };

    default:
      return state;
  }
};

export default getMyProgressReducer;
