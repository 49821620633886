import { ExpertQuestionTypes } from "./types";
import { expertQuestion } from "../../../api/index";

export const submitExpertQuestionRequest = (
  lesson,
  subject,
  questionContent
) => async (dispatch) => {
  dispatch({ type: ExpertQuestionTypes.EXPERT_QUESTION_LOADING });
  try {
    const modifiedObject = {
      lesson,
      subject,
      questionContent,
    };
    const response = await expertQuestion(modifiedObject);
    dispatch({
      type: ExpertQuestionTypes.EXPERT_QUESTION_SUCCESS,
      payload:
        response.status == 200
          ? "Your query has been sent!"
          : "Something went wrong",
    });
  } catch (err) {
    const error = !err?.response?.data?.message
      ? "Something went wrong"
      : err?.response?.data?.message;
    dispatch({
      type: ExpertQuestionTypes.EXPERT_QUESTION_ERROR,
      payload: error,
    });
  }
};
