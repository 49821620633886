import { combineReducers } from "redux";
import loginReducer from "./auth/login/reducer";
import registerReducer from "./auth/register/reducer";
import forgotPasswordReducer from "./auth/forgot-password/reducer";
import resetPasswordReducer from "./auth/reset-password/reducer";
import appInfoReducer from "./app-info/reducer";
import getMyCoursesReducer from "./courses/get-my-courses/reducer";
import getMyprogressReducer from "./courses/get-my-progress/reducer";
import updateProgressReducer from "./courses/update-progress/reducer";
import getLessonDetailsReducer from "./lessons/get-lesson-details/reducer";
import submitQuizAnswerReducer from "./lessons/submit-quiz-answer/reducer";
import getLessonUploadsReducer from "./lessons/get-lesson-uploads/reducer";
import submitLessonUploadsReducer from "./lessons/submit-lesson-uploads/reducer";
import uploadFilesReducer from "./lessons/upload-files/reducer";
import profileReducer from "./profile/reducer";
import getCourseCategoryReducer from "./courses/get-course-categories/reducer";
import startCourseReducer from "./courses/start-course/reducer";
import videoLogsReducer from "./logs/video-logs/reducer";
import articleLogsReducer from "./logs/article-logs/reducer";
import expertQuestionReducer from "./lessons/expert-question/reducer";
export default combineReducers({
  loginReducer,
  registerReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  expertQuestionReducer,
  appInfoReducer,
  articleLogsReducer,
  getMyCoursesReducer,
  videoLogsReducer,
  getMyprogressReducer,
  updateProgressReducer,
  getLessonDetailsReducer,
  submitQuizAnswerReducer,
  getLessonUploadsReducer,
  submitLessonUploadsReducer,
  uploadFilesReducer,
  profileReducer,
  getCourseCategoryReducer,
  startCourseReducer,
});
