import React, { useState, useEffect } from "react";
import styles from "./Signup.module.scss";
import bg from "../../Assets/extra/Signup-bg.svg";
import Logo from "../../Assets/extra/MASTERS LOGO-02.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Register } from "../../redux/auth/register/actions";
import { GetAppInfo } from "../../redux/app-info/actions";
import { Alert } from "reactstrap";
import Loader from "../../Components/Loader/Loader";
import Google from "../../Assets/extra/gOOGLE.png";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fieldError, setFieldError] = useState("");
  const [userData, setUserData] = useState({
    country: 1,
    email: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    password: "",
    placeOfWork: 1,
    speciality: 1,
    username: "",
    scfhs: "",
  });
  // Accessing register reducer
  const { error, loading } = useSelector((state) => state.registerReducer);
  const { countries, specialities } = useSelector(
    (state) => state.appInfoReducer
  );
  // Getting specialities and countries from api
  useEffect(() => {
    dispatch(GetAppInfo());
  }, []);
  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeSelection = (e) => {
    let selectedOption;
    if (e.target.name == "speciality") {
      selectedOption = specialities[e.target.selectedIndex];
    }
    if (e.target.name == "country") {
      selectedOption = countries[e.target.selectedIndex];
    }
    setUserData({
      ...userData,
      [e.target.name]: selectedOption.id,
    });
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  // final hitting of register api
  const register = () => {
    if (userData.country == 3 && !userData.scfhs) {
      setFieldError("Scfhs is required!");
      return;
    }
    setFieldError("");
    userData.username = userData.email;
    dispatch(Register(userData, history));
  };
  //  will load screen when api hits
  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles.signup}>
      <div className={styles.div1}>
        <div className={styles.picture}>
          <img src={bg} alt="pic" />
          <h4>
            Welcome to the MASTERS Program!
            <br/>
            Your simple & reliable advanced courses platform
          </h4>
        </div>
        <div className={styles.div1Logo}>
          <img className={styles.logoImage} src={Logo} alt="pic" />
        </div>
      </div>
      <div className={styles.div2}>
        <div className={styles.right}>
          <div className={styles.div2_intro}>
            <div>
              <h2>
                Welcome To Type-2 <br /> Masterclass
              </h2>
            </div>
            <div>
              {error &&
                error.map((err) => <Alert color="danger">{err.message}</Alert>)}
              {fieldError ? <Alert color="danger">{fieldError}</Alert> : null}
            </div>
            <div>
              <p>Create your account to use the portal</p>
            </div>
          </div>

          <div className={styles.set}>
            <div className={styles.flexSection3}>
              <section className={styles.flexSection3_1}>
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  name="email"
                  onChange={(e) => handleChange(e)}
                />
              </section>
              <section className={styles.flexSection3_2}>
                <label>Password</label>
                <div className={styles.input_icon_wrap}>
                  <input
                    placeholder="Enter Password"
                    type={passwordShown ? "text" : "password"}
                    className={styles.input_with_icon}
                    name="password"
                    onChange={(e) => handleChange(e)}
                  />
                  <span className={styles.input_icon}>
                    <span
                      onClick={togglePasswordVisiblity}
                      class={
                        passwordShown == true
                          ? "far fa-eye"
                          : "far fa-eye-slash"
                      }
                    ></span>
                  </span>
                </div>
              </section>
            </div>
            <div className={styles.flexSection}>
              <section>
                <label>First Name</label>
                <input
                  type="text"
                  placeholder="Enter your first name"
                  name="firstName"
                  onChange={(e) => handleChange(e)}
                />
              </section>
              <section>
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Enter your last name"
                  name="lastName"
                  onChange={(e) => handleChange(e)}
                />
              </section>
            </div>

            <div className={styles.flexSection}>
              <section>
                <label>Speciality</label>
                <div className={styles.flexSection2}>
                  <select
                    placeholder="Choose Your Speciality"
                    name="speciality"
                    onChange={(e) => handleChangeSelection(e)}
                  >
                    {specialities &&
                      specialities.map((speciality) => (
                        <option key={speciality.id}>{speciality.title}</option>
                      ))}
                  </select>
                </div>
              </section>
              <section className={styles.flexSectionNumber}>
                <label>Hospital</label>
                <input
                  placeholder="Choose your hospital"
                  name="placeOfWork"
                  onChange={(e) => handleChange(e)}
                />
              </section>
            </div>

            <div className={styles.flexSection}>
              <section>
                <label>Country</label>
                <div className={styles.flexSection2}>
                  <select
                    placeholder="Choose your country"
                    name="country"
                    onChange={(e) => handleChangeSelection(e)}
                  >
                    {countries &&
                      countries.map((country) => (
                        <option key={country.id}>{country.title}</option>
                      ))}
                  </select>
                </div>
              </section>
              <section className={styles.flexSectionNumber}>
                <label>Mobile Number</label>
                <input
                  type="number"
                  placeholder="Enter your mobile number"
                  name="mobileNumber"
                  onChange={(e) => handleChange(e)}
                />
              </section>
            </div>
            <div className={styles.flexSection3}>
              <section className={styles.flexSection3_1}>
                <label>SCFHS Number (for KSA only)</label>
                <input
                  type="text"
                  placeholder="Your SCFHS Number"
                  name="scfhs"
                  onChange={(e) => handleChange(e)}
                />
              </section>
            </div>
            <section className={styles.button}>
              <button onClick={register}>Sign up</button>
            </section>
            <section className={styles.already}>
              <span>Already have an account, </span>{" "}
              <Link to="/" className={styles.link}>
                Login here
              </Link>
            </section>
            <div className={styles.or}>
              <p>OR</p>
              <div></div>
            </div>
            <section>
              <button className={styles.buttongoogle}>
                <span>
                  <img src={Google} />{" "}
                </span>
                Sign in with Google
              </button>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
