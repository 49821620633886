import React, { useEffect, useState } from "react";
import styles from "./courseIntroduction.module.scss";
import Sponser from "../../Components/Sponser/Sponser";
import Header from "../../Components/Shared/Header/Header";
import { Col, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetMyCoursesRequest } from "../../redux/courses/get-my-courses/actions";
import Loader from "../../Components/Loader/Loader";
import ReactPlayer from "react-player";
import MobileHeader from "../../Components/Shared/MobileHeader/MobileHeader";
import Markdown from "markdown-to-jsx";
import Footer from "../../Components/Footer/Footer";
import { StartCourse } from "../../redux/courses/start-course/actions";

export const lessonFilter = (data) => {
  let filteredLesson = [];
  data?.modules?.map((mod) => {
    mod?.lessons.map((lesson) => {
      if (!filteredLesson.includes(lesson.id)) {
        filteredLesson.push(lesson.id);
      }
    });
  });
  return filteredLesson;

};

const CourseIntroduction = () => {
  const [filteredLessons, setFilteredLessons] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const newCourse = new URLSearchParams(search).get("newCourse");
  useEffect(() => {
    if (newCourse) return
    dispatch(GetMyCoursesRequest(history));
  }, []);
  const { data, loading, error } = useSelector(
    (state) => state.getMyCoursesReducer
  );
  const startCourseReducer = useSelector(
    (state) => state.startCourseReducer
  );

  useEffect(() => {
    setFilteredLessons(lessonFilter(data));
  }, [data]);
  if (loading || startCourseReducer?.loading) {
    return <Loader />;
  }
  return (
    <>
      <Header course={data} hideBackArrow={true} />
      <MobileHeader course={data} hideBackArrow={true} />

      <div className={styles.courseIntro}>
        <div className={styles.courseDiv}>
          <Row className="m-0">
            <Col md={4} lg={4}>
              <div className={styles.courseDiv1}>
                <img src={data?.logo?.url} alt="logo" />
              </div>
            </Col>
            <Col md={8} lg={8}>
              <div className={styles.courseDiv2}>
                <h2>{data?.title}</h2>
                {data?.Description ? (
                  <Markdown options={{ wrapper: "article" }}>
                    {data?.Description}
                  </Markdown>
                ) : null}

                <div className="mt-2 mb-2">
                  <ReactPlayer
                    className="react-player"
                    url={data?.introVideo?.url}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div>
                {/*<Sponser />*/}
                {
                  newCourse ? <button
                    onClick={(e) => dispatch(StartCourse(data?.id, history))}
                  >
                    Start Course
                  </button> :
                    <button
                      onClick={(e) => history.push(`/lesson/${filteredLessons[0]}`)}
                    >
                      Start Course
                    </button>
                }


              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CourseIntroduction;
