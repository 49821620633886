import { SubmitLessonUploadsTypes } from "./types";
import { submitLessonUploads } from "../../../api/index";

export const submitLessonUploadsRequest = (lessonId, uploads) => async (
  dispatch
) => {
  dispatch({ type: SubmitLessonUploadsTypes.SUBMIT_LESSON_UPLOADS_LOADING });
  try {
    const { data } = await submitLessonUploads(lessonId, uploads);
    dispatch({
      type: SubmitLessonUploadsTypes.SUBMIT_LESSON_UPLOADS_SUCCESS,
      payload: data,
    });
    console.log(data);
  } catch (err) {
    console.log(`errorrr`, err.response.data.message);
    const error = !err.response.data.message
      ? "Something went wrong"
      : err.response.data.message;
    dispatch({
      type: SubmitLessonUploadsTypes.SUBMIT_LESSON_UPLOADS_ERROR,
      payload: error,
    });
  }
};
