import { RegisterTypes } from "./types";
import { register } from "../../../api/index";
import setAuthToken from "../../../config/setAuthToken";

export const Register = (Data, history) => async (dispatch) => {
  dispatch({ type: RegisterTypes.REGISTER_LOADING });
  try {
    const { data } = await register(Data);
    const { jwt, user } = data;

    dispatch({
      type: RegisterTypes.REGISTER_SUCCESS,
      payload: { jwt, user },
    });
    //  set token to auth header
    setAuthToken(jwt);
    history.push("/course-introduction")
  } catch (err) {
    console.log(`errorrr`, err.response.data.message[0].messages);
    const error = !err.response.data.message[0].messages
      ? history.push("/error")
      : err.response.data.message[0].messages;
    dispatch({
      type: RegisterTypes.REGISTER_ERROR,
      payload: error,
    });
  }
};
