import { AppInfoTypes } from "./types";
import { getAppInfo } from "../../api/index";

export const GetAppInfo = () => async (dispatch) => {
  dispatch({ type: AppInfoTypes.APP_INFO_LOADING });
  const { data } = await getAppInfo();
  const { countries, specialities } = data;
  try {
    dispatch({
      type: AppInfoTypes.APP_INFO_SUCCESS,
      payload: { countries, specialities },
    });
  } catch (err) {
    const error = err.response.data.message[0].messages
      ? "Something went wrong"
      : err.response.data.message[0].messages;

    dispatch({
      type: AppInfoTypes.APP_INFO_ERROR,
      payload: error,
    });
  }
};
