import { GetMyProgressTypes } from "./types";
import { getMyProgress } from "../../../api/index";

export const GetMyProgressRequest = () => async (dispatch) => {
  dispatch({ type: GetMyProgressTypes.GET_MY_PROGRESS_LOADING });
  try {
    const { data } = await getMyProgress();
    dispatch({
      type: GetMyProgressTypes.GET_MY_PROGRESS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(`errorrr`, err.response.data.message);
    const error = !err.response.data.message
      ? "Something went wrong"
      : err.response.data.message;
    dispatch({
      type: GetMyProgressTypes.GET_MY_PROGRESS_ERROR,
      payload: error,
    });
  }
};
