import React, { useState,useEffect } from "react";
import styles from "./LessonStreaming.module.scss";
import SideNav from "../../Components/Shared/SideNav/SideNav";
import Header from "../../Components/Shared/Header/Header";
import TopButtons from "../../Components/TopButtons/TopButtons";
import ReactPlayer from "react-player";
import MobileHeader from "../../Components/Shared/MobileHeader/MobileHeader";
import { ReactComponent as Hamburger } from "../../Assets/hamburger.svg";
import CountdownTimer from "../../Components/CountdownTimer/CountdownTimer";
import { useHistory } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import Markdown from "markdown-to-jsx";
import Footer from "../../Components/Footer/Footer";
import ExpertQuestion from "../../Components/ExpertQuestion/ExpertQuestion";
import { clock, getSeconds, getMyTimer, setSeconds } from "./service";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { submitVideoLogs } from "../../redux/logs/video-logs/actions";

import "./override.scss";
const LessonStreaming = ({ data }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState(false);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    if (data?.material[0].recording) {
      clearInterval(getMyTimer());
      if (getSeconds() != 0) {
        dispatch(submitVideoLogs(data.id, getSeconds()));
      }
      setSeconds(0);
    }
    return;
  }, [location]);
  return (
    <>
      <Header hideLogo={true} />
      <MobileHeader hideLogo={true} />

      <Row className={styles.main} noGutters>
        <Col lg={9} md={9} sm={12} className={styles.course}>
          <>
            <div className={styles.backArrowContainer}>
              <Hamburger
                className={styles.icon}
                onClick={() => setMobile(true)}
              />
            </div>
            <TopButtons
              courseTitle={data.title}
              lesson={''}
              Data={data}
            />

            {data.material.map((item, i) => {
              return (
                <>
                  <div
                    className={styles.frame}
                    style={{
                      backgroundImage: `url(${item.thumbnail?.url})`,
                    }}
                  >
                    {item?.streamingURL ? (
                      <iframe
                        width="100%"
                        height="100%"
                        src={item.streamingURL}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    ) : null}
                    {!item?.streamingURL && !item?.recording ? (
                      <CountdownTimer
                        date={item.streamingStart}
                        history={history}
                        title={data?.title}
                      />
                    ) : null}
                    {item?.recording ? (
                      <ReactPlayer
                        className="react-player"
                        url={item?.recording.url}
                        controls={true}
                        playing={true}
                        onPlay={clock}
                        onPause={(play) => clearInterval(getMyTimer())}
                        light={item.thumbnail?.url}
                        width="100%"
                        config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        height="100%"
                      />
                    ) : null}
                  </div>
                  {item.speaker == null ? null : (
                    <Button
                      className={`${styles.courseDivButton2} mt-4`}
                      onClick={(e) => setModal(true)}
                    >
                      {`Ask ${item.speaker}`}
                    </Button>
                  )}
                </>
              );
            })}
            <div className={styles.about}>
              <Markdown options={{ wrapper: "article" }}>
                {data?.Description}
              </Markdown>
            </div>
          </>
        </Col>

        <Col lg={3} md={3} sm={12} className={styles.main2}>
          <SideNav mobile={mobile} setMobile={setMobile} id={data.id} />
        </Col>
      </Row>
      <ExpertQuestion
        modal={modal}
        setModal={setModal}
        id={data.id}
        professorName={data.material[0].speaker}
      />
      <Footer />
    </>
  );
};

export default LessonStreaming;
