import { SubmitLessonUploadsTypes } from "./types";

const INITIAL_STATE = {
  error: null,
  loading: false,
  data: null,
};

const submitLessonUplaodsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubmitLessonUploadsTypes.SUBMIT_LESSON_UPLOADS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SubmitLessonUploadsTypes.SUBMIT_LESSON_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SubmitLessonUploadsTypes.SUBMIT_LESSON_UPLOADS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default submitLessonUplaodsReducer;
