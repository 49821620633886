import React, { useState, useEffect } from "react";
import styles from "./resetPassword.module.scss";
import bg from "../../Assets/bg.svg";
import Logo from "../../Assets/extra/MASTERS LOGO-02.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../../redux/auth/reset-password/actions";
import { Alert } from "reactstrap";
import Loader from "../../Components/Loader/Loader";
import queryString from "query-string";
const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const parsed = queryString.parse(window.location.search);
  const [isValidate, setIsValidate] = useState(true);
  const [resetPasswordFields, setPasswordFields] = useState({
    password: "",
    passwordConfirmation: "",
    ...parsed,
  });
  useEffect(() => {
    if (
      resetPasswordFields.code &&
      resetPasswordFields.password &&
      resetPasswordFields.passwordConfirmation
    ) {
      setIsValidate(false);
    }
  }, [parsed, resetPasswordFields]);
  const handleChange = (e) => {
    setPasswordFields({
      ...resetPasswordFields,
      [e.target.name]: e.target.value,
    });
  };
  // Accessing forgot password reducer
  const { error, loading } = useSelector((state) => state.resetPasswordReducer);
  // final hitting of forgot password api
  const resetPasswordConfirm = () => {
    dispatch(resetPassword(resetPasswordFields, history));
  };

  //  will load screen when api hits

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles.Reset}>
      <div className={styles.div1}>
        <div className={styles.picture}>
          <img src={bg} alt="pic" />
          <h4>
            Welcome to the MASTERS Program!
            <br/>
            Your simple & reliable advanced courses platform
          </h4>
        </div>
        <div className={styles.div1Logo}>
          <img src={Logo} alt="pic" />
        </div>
      </div>
      <div className={styles.div2}>
        <div className={styles.right}>
          <div className={styles.headings}>
            <section>
              <h3>Reset Password</h3>
              <div>
                {error &&
                  error.map((err) => (
                    <Alert color="danger">{err.message}</Alert>
                  ))}
              </div>
            </section>
            <section>
              <p>Enter new password to reset your previous password</p>
            </section>
          </div>
          <div className={styles.mobile}>
            <section>
              <label>New Password</label>
              <input
                type="password"
                name="password"
                placeholder="Enter new password"
                className={styles.password}
                onChange={(e) => handleChange(e)}
              />
            </section>
            <section>
              <label>Confirm Password</label>
              <input
                type="password"
                name="passwordConfirmation"
                placeholder="Confirm your password"
                className={styles.password}
                onChange={(e) => handleChange(e)}
              />
            </section>
            <section className={styles.button}>
              <button onClick={resetPasswordConfirm} disabled={isValidate}>
                Reset Password
              </button>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
