import { UploadFilesTypes } from "./types";
import { uploadFiles } from "../../../api/index";
import { submitLessonUploadsRequest } from "../../../redux/lessons/submit-lesson-uploads/actions";

export const uploadFilesRequest = (
  files,
  lessonId,
  history,
  previousIds
) => async (dispatch) => {
  dispatch({ type: UploadFilesTypes.UPLOAD_FILES_LOADING });
  try {
    const { data } = await uploadFiles(files);
    const fileIdFunction = () => {
      let fileId = [...previousIds];
      data.forEach((file) => {
        fileId.push(file.id);
      });

      return fileId;
    };
    dispatch(
      submitLessonUploadsRequest(lessonId, { uploads: fileIdFunction() })
    );

    dispatch({
      type: UploadFilesTypes.UPLOAD_FILES_SUCCESS,
      payload: "Assignment submitted successfully.",
    });
    history.go(0)
  } catch (err) {
    console.log(`errorrr`, err.response.data.message);
    const error = !err.response.data.message
      ? history.push("/error")
      : err.response.data.message;
    dispatch({
      type: UploadFilesTypes.UPLOAD_FILES_ERROR,
      payload: error,
    });
  }
};
