import React, { useEffect, useState } from "react";
import styles from "./topButton.module.scss";
import Button from "@material-ui/core/Button";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateProgressRequest } from "../../redux/courses/update-progress/actions";
import Loader from "../../Components/Loader/Loader";
import { GetMyProgressRequest } from "../../redux/courses/get-my-progress/actions";
import { GetMyCoursesRequest } from "../../redux/courses/get-my-courses/actions";

const TopButtons = ({ courseTitle, lesson, nextLesson, Data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filteredLessons, setFilteredLessons] = useState([]);
  useEffect(() => {
    dispatch(GetMyProgressRequest());
    dispatch(GetMyCoursesRequest());
  }, []);
  const { update_progress_data, loading, error } = useSelector(
    (state) => state.updateProgressReducer
  );
  const { progress_data, progress_loading } = useSelector(
    (state) => state.getMyprogressReducer
  );
  const { video_logs_error, video_logs_loading } = useSelector(
    (state) => state.videoLogsReducer
  );
  const { data } = useSelector((state) => state.getMyCoursesReducer);
  const lessonFilter = () => {
    data?.modules?.map((mod) => {
      mod?.lessons.map((lesson) => {
        if (!filteredLessons.includes(lesson.id)) {
          filteredLessons.push(lesson.id);
        }
      });
    });
  };
  const getPreviousLesson = (currentLesson) => {
    let index = filteredLessons.indexOf(currentLesson);
    return filteredLessons[index - 1];
  };
  const getNextLesson = (currentLesson) => {
    let index = filteredLessons.indexOf(currentLesson);
    return filteredLessons[index + 1];
  };
  useEffect(() => {
    lessonFilter();
  }, [data]);
  const handleNext = () => {
    let obj = { lesson: Data.id };
    dispatch(UpdateProgressRequest(obj, getNextLesson(Data.id), history));

  };
  const handlePrevious = () => {
    let previousLesson = getPreviousLesson(Data.id);
    if (previousLesson == undefined) {
      history.push("/course-introduction");
    } else {
      history.push(`/lesson/${previousLesson}`);
    }
  };

  if (loading || progress_loading || video_logs_loading) {
    return <Loader />;
  }
  return (
    <Row className={styles.main_container} noGutters>
      <Col md={2} lg={1} sm={12} className={styles.skip_div}>
        <Button className={styles.courseDivButton1} onClick={handlePrevious}>
          <SkipPreviousIcon />
        </Button>
      </Col>
      <Col md={7} lg={8} sm={12}>
        <span className={styles.spanText}>{lesson}</span>
        <h6 className={styles.heading}>{courseTitle}</h6>
      </Col>
      {nextLesson == false ? null : (
        <Col md={3} sm={12} className={styles.next_div}>
          <Button
            className={styles.courseDivButton2}
            endIcon={<SkipNextIcon />}
            onClick={handleNext}
          >
            Next Lesson
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default TopButtons;
