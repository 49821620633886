import { GetMyCoursesTypes } from "./types";
import { getMyCourses } from "../../../api/index";

export const GetMyCoursesRequest = (history) => async (dispatch) => {
  dispatch({ type: GetMyCoursesTypes.GET_MY_COURSES_LOADING });
  try {
    const { data } = await getMyCourses();
    dispatch({
      type: GetMyCoursesTypes.GET_MY_COURSES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err?.response?.status === 404) {
      history.push("/curriculum");
    }
    const error = !err.response?.data?.message
      ? "Something went wrong"
      : err?.response?.data?.message;
    dispatch({
      type: GetMyCoursesTypes.GET_MY_COURSES_ERROR,
      payload: error,
    });
  }
};

export const SetCourse = (course) => async (dispatch) => {
  dispatch({
    type: GetMyCoursesTypes.GET_MY_COURSES_SUCCESS,
    payload: course,
  });
};
