import React, { useEffect } from "react";
import styles from "./Curriculums.module.scss";
import bg from "../../Assets/extra/Curriculum_bg.svg";
import Logo from "../../Assets/extra/MASTERS LOGO-02.svg";
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { GetCourseCategory } from "../../redux/courses/get-course-categories/actions";
import { SetCourse } from "../../redux/courses/get-my-courses/actions";
import { useHistory } from "react-router-dom";
const Curriculums = () => {
  const dispatch = useDispatch();
  const history=useHistory();
  useEffect(() => {
    dispatch(GetCourseCategory());
  }, []);
  const { data, loading } = useSelector(
    (state) => state.getCourseCategoryReducer
  );
  const startCourseReducer = useSelector((state) => state.startCourseReducer);
  if (loading || startCourseReducer?.loading) return <Loader />;
  return (
    <div className={styles.login}>
      <div className={styles.div1}>
        <div className={styles.picture}>
          <img src={bg} alt="pic" />
          <h4>
            Welcome To The MASTERS Program!
            <br/>
            Your Simple & Reliable Advanced Courses Platform
          </h4>
        </div>
        <div className={styles.div1Logo}>
          <img src={Logo} alt="pic" />
        </div>
      </div>
      <div className={styles.div2}>
        <div className={styles.right}>
          <div className={styles.headings}>
            <section>
              <h2>Choose Your Curriculums</h2>
            </section>
          </div>
          <div className={styles.bg_color}>
            {
              data ? data?.map(course => (
                <div className={styles.main_div3} key={course?.id}>
                  <div className={styles.heading_div}>
                    <p>{course?.title}</p>
                  </div>
                  {
                    course?.courses ? course?.courses.map(crs => (
                      <div className={styles.divider} key={crs?.id} onClick={() => {
                        dispatch(SetCourse(crs))
                        history.push("/course-introduction?newCourse=true")
                      }}>
                        <button className={styles.div}>
                          <p>{crs?.title}</p>
                        </button>
                      </div>
                    )) : null
                  }

                </div>
              )) : null
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default Curriculums;
