import React from "react";
import styles from "./footer.module.scss";
import {Row, Col, Container} from "reactstrap";
import Sponser from "../../Assets/Footer/sanofi-new.png";
import Otb from "../../Assets/Footer/otb.png";
import {useSelector} from "react-redux";
import Markdown from "markdown-to-jsx";

const Footer = () => {

    const { data, loading, error } = useSelector(
        (state) => state.getMyCoursesReducer
    );

    return (
        <div className={styles.main_container}>
            <Row noGutters>
                <Col className={styles.description}>
                    {data?.footerText ? (
                        <Markdown options={{ wrapper: "article" }}>
                            {data?.footerText}
                        </Markdown>
                    ) : null}
                </Col>
            </Row>
            <Row noGutters>

                <Col md={3} sm={12}/>

                <Col md={2} sm={12} className={styles.powered_by}>
                    <p>Powered By:</p>
                    <img src={Otb} className={styles.otb_logo}/>
                </Col>
                <Col md={2} sm={12} className={styles.educational_support}>
                    <p>Educational Support:</p>
                    <img src={Sponser} className={styles.sanofi_logo}/>
                </Col>
                <Col md={2} sm={12} className={styles.educational_support}>
                    <p>Collaboration With:</p>
                    <img src={data?.universityLogo?.url} className={styles.sanofi_logo}/>
                </Col>
                <Col md={3} sm={12}/>
            </Row>
        </div>
    );
};

export default Footer;
